import React, { Component, Fragment } from "react";
import { Router } from "@reach/router";
import Project from "../templates/Project.js";
import Publication from "../templates/Publication.js";

class PreviewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document: null,
      type: null,
    };
  }

  async fetchAPI(query, { variables } = {}) {
    const apiUrl =
      "https://w3tlk96v.api.sanity.io/v1/graphql/production/default";
    const res = await fetch(apiUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    const json = await res.json();

    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }

    return json.data;
  }

  async componentDidMount() {
    const { id } = this.props;

    const queryDraft = `
      query draftQuery($id: ID!) {
        document: Document(id: $id) {
          _type
        }
      }
    `;

    const { document } = await this.fetchAPI(queryDraft, { variables: { id } });
    const { _type: type } = document;
    this.setState({ type });

    const ImageFragment = `
      fragment ImageFragment on Image {
        asset {
          _id
        }
        crop {
          bottom
          left
          right
          top
        }
        hotspot {
          height
          width
          x
          y
        }
      }
    `;

    const queryProject = `
      ${ImageFragment}
      query ProjectPageQuery($id: ID!) {
        project: Project(id: $id) {
          title
          subtitle
          headerImageAlt
          headerImage {
            ...ImageFragment
          }
          _rawLeadParagraph: leadParagraphRaw
          _rawContent: content {
            _key
            backgroundColor {
              value
            }
            sectionContent: sectionContentRaw
          }
          meta {
            metaTitle
            metaDescription
          }
          isCaseStudy
          card {
            duration
            services
            teamSize
            link {
              label
              url
            }
            clients {
              title
            }
            partners {
              title
            }
          }
          linkProjects {
            ... on Project {
              _type
              title
              subtitle
              slug {
                current
              }
              thumbImage {
                ...ImageFragment
              }
            }
            ... on Publication {
              _type
              title
              subtitle
              slug {
                current
              }
              thumbImage {
                ...ImageFragment
              }
            }
          }
        }
      }
    `;

    const queryPublication = `
      ${ImageFragment}
      query PublicationPageQuery($id: ID!) {
        publication: Publication(id: $id) {
          publishedAtLocaleFr: publishedAt
          publishedAt
          author
          title
          subtitle
          headerImageAlt
          headerImage {
            ...ImageFragment
          }
          _rawLeadParagraph: leadParagraphRaw
          _rawContent: content {
            _key
            backgroundColor {
              value
            }
            sectionContent: sectionContentRaw
          }
          meta {
            metaTitle
            metaDescription
          }
          linkPublications {
            ... on Project {
              _type
              title
              subtitle
              slug {
                current
              }
              thumbImage {
                ...ImageFragment
              }
            }
            ... on Publication {
              _type
              title
              subtitle
              slug {
                current
              }
              thumbImage {
                ...ImageFragment
              }
            }
          }
        }
      }
    `;

    switch (type) {
      case "project": {
        const doc = await this.fetchAPI(queryProject, {
          variables: { id },
        });
        this.setState({ document: doc });
        break;
      }
      case "publication": {
        const doc = await this.fetchAPI(queryPublication, {
          variables: { id },
        });
        this.setState({ document: doc });
        break;
      }
      default:
        return;
    }
  }

  render() {
    const { document, type } = this.state;
    return (
      <div>
        {document && (
          <Fragment>
            <div>{type === "project" && <Project data={document} />}</div>
            <div>
              {type === "publication" && <Publication data={document} />}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

const Previews = () => {
  return (
    <div>
      <Router>
        <PreviewPage path="/preview/:id" />
      </Router>
    </div>
  );
};

export default Previews;
